<template>
    <v-carousel
            cycle
            :height="$vuetify.breakpoint.smAndUp ? '150' : '150'"
            hide-delimiter-background
            hide-delimiters
            :show-arrows="false"
            :class="$vuetify.breakpoint.smAndUp ? 'ml-10' : ''"
    >
        <v-carousel-item
                v-for="(rating, i) in AppStore.data.unit.ratings"
                :key="i"
        >
<!--            <v-sheet-->
<!--                    height="100%"-->
<!--            >-->
                <v-container fluid class="py-5">
                    <v-row no-gutters style="flex-wrap: nowrap;">
                        <v-col class="flex-grow-0 flex-shrink-0 mr-4" v-if="$vuetify.breakpoint.smAndUp">
                            <svg width="50" height="40" viewBox="0 0 117 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_165_24559)">
                                    <path d="M26.3638 0C29.8928 0.00520344 33.3859 0.70918 36.6415 2.07126C39.8971 3.43334 42.8507 5.42658 45.332 7.936C47.8133 10.4454 49.7731 13.4213 51.0984 16.692C52.4237 19.9627 53.0882 23.4635 53.0536 26.9924C53.019 34.1728 50.14 41.0467 45.0472 46.1087C39.9544 51.1706 33.0631 54.0079 25.8826 53.9989C25.7375 53.9989 25.5162 53.9723 25.3517 53.9723C31.4251 58.841 38.9776 61.4924 46.7616 61.4887C47.6467 61.4908 48.5227 61.6672 49.3396 62.0079C50.1565 62.3485 50.8983 62.8467 51.5226 63.474C52.147 64.1013 52.6417 64.8455 52.9784 65.664C53.3152 66.4825 53.4875 67.3593 53.4854 68.2443C53.5547 69.0962 53.4485 69.9533 53.1735 70.7626C52.8984 71.5718 52.4602 72.3161 51.886 72.9493C51.3119 73.5825 50.614 74.0911 49.8354 74.4439C49.0569 74.7966 48.2143 74.9859 47.3597 75C41.0907 74.9796 34.8876 73.7192 29.1077 71.2916C23.3278 68.8639 18.0852 65.3168 13.6817 60.8547C9.27829 56.3925 5.80095 51.1035 3.45006 45.2919C1.09916 39.4803 -0.0788717 33.2611 -0.0162971 26.9924V26.9782C-0.085846 23.4666 0.544583 19.9762 1.83821 16.7108C3.13185 13.4454 5.06279 10.4704 7.51838 7.95911C9.97397 5.44785 12.905 3.45072 16.1406 2.08422C19.3762 0.717723 22.8515 0.00917806 26.3638 0Z" :fill="AppStore.data.general.primary_color.hexa"/>
                                    <path d="M89.6049 0C93.1341 0.00639142 96.6271 0.711054 99.8827 2.07342C103.138 3.43579 106.092 5.4289 108.574 7.93794C111.056 10.447 113.017 13.4223 114.344 16.6925C115.671 19.9628 116.337 23.4633 116.305 26.9924C116.274 34.1698 113.399 41.0423 108.311 46.1042C103.222 51.1662 96.3348 54.005 89.1573 53.9989C89.0122 53.9989 88.7875 53.9723 88.6406 53.9723C94.7134 58.8422 102.266 61.4938 110.05 61.4887C110.936 61.4908 111.812 61.6672 112.628 62.0079C113.445 62.3485 114.187 62.8467 114.811 63.474C115.436 64.1013 115.931 64.8455 116.267 65.664C116.604 66.4825 116.776 67.3593 116.774 68.2443C116.839 69.0982 116.729 69.9565 116.45 70.7662C116.171 71.5758 115.73 72.3199 115.153 72.9526C114.575 73.5854 113.875 74.0935 113.094 74.4456C112.314 74.7977 111.469 74.9863 110.613 75C104.342 74.98 98.1374 73.7202 92.3553 71.2932C86.5732 68.8661 81.3281 65.3196 76.9218 60.8578C72.5154 56.3961 69.0349 51.1071 66.6803 45.2951C64.3256 39.4832 63.1435 33.2629 63.2018 26.9924V26.9782C63.1368 23.4659 63.7711 19.9757 65.0678 16.7108C66.3645 13.4459 68.2976 10.4716 70.7547 7.96094C73.2119 5.45029 76.1439 3.45343 79.3801 2.0867C82.6163 0.71998 86.092 0.0106622 89.6049 0Z" :fill="AppStore.data.general.primary_color.hexa"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_165_24559">
                                        <rect width="116.781" height="75" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </v-col>

                        <div style="min-width: 100px; max-width: 100%;" class="flex-grow-0 flex-shrink-1">
                            <div class="d-flex flex-column">
                                <div class="d-flex flex-row">
                                    <v-rating
                                            half-increments
                                            dense
                                            readonly
                                            size="25"
                                            color="orange"
                                            background-color="grey"
                                            v-model="rating.rate"
                                            icon-label=""
                                            class="mr-2 carrousel-stars"></v-rating>

                                    <span class="font-weight-bold" style="margin-top: 3px;">{{
											rating.rate + '/5'
                                        }}</span>
                                </div>
                            </div>
                            <div class="d-flex flex-column ml-1 mt-1">
                                    <span class="mr-auto font-weight-medium caption grey--text text-body-1 text--darken-1"
                                          v-if="rating.source === 'google' || rating.source === 'google_places'">
                                              {{ $t('views.timeline.rating_from') }} {{ rating.consumer.identifier }}
                                               <span v-if="rating.consumer.influence" class="caption">
                                               {{ $t('views.home.local_guide') }} {{ rating.consumer.influence / 10 }}
                                               </span> {{ $t('views.timeline.of') }}     <span class="d-inline-block grey--text text--darken-1"
                                                                    style="margin-top: 2px">
                                        {{ Helpers.parseDate(rating.posted_at_tz, 'DD/MM/YYYY') }}
                                        </span>
                                          </span>

                                <span class="font-weight-medium caption grey--text text-body-1 text--darken-1"
                                      v-else-if="rating.source === 'civiliz' && rating.consumer && rating.consumer.signature !== 'anonymous' && rating.consumer.signature !== 'anonyme'">
                                            {{ $t('views.timeline.rating_from') }} {{ rating.consumer.signature }} {{ $t('views.timeline.of') }}     <span
                                        class="d-inline-block grey--text text--darken-1"
                                        style="margin-top: 2px">
                                        {{ Helpers.parseDate(rating.posted_at_tz, 'DD/MM/YYYY') }}
                                        </span>
                                          </span>
                                <span v-else class="font-weight-medium text-body-1 grey--text text--darken-1">
                                         {{ $t('views.timeline.rating_of') }} <span
                                    class="d-inline-block grey--text text--darken-1"
                                    style="margin-top: 2px">
                                        {{ Helpers.parseDate(rating.posted_at_tz, 'DD/MM/YYYY') }}
                                        </span>
                                    </span>
                            </div>
                            <div class="mt-3 block-ellipsis ml-1 text-body-2" v-if="rating.contents.length">
                                {{ rating.contents[0].content }}
                            </div>
                        </div>
                    </v-row>
                </v-container>
<!--            </v-sheet>-->
        </v-carousel-item>
    </v-carousel>
</template>

<script>
import AppStore from "../../../stores/AppStore";
import Helpers from "../../../services/Helpers";

export default {
    name: 'ratings-carousel',

    components: {},

    props: {},

    data: () => ({
        AppStore,
        Helpers,
    }),

    created() {

    },

    mounted() {

    },

    computed: {},

    watch: {},

    methods: {}
};
</script>
