<template>
    <ValidationObserver v-slot="{ invalid, validated, passes, validate, handleSubmit }" ref="observer">

        <v-dialog v-model="dialog" max-width="700px" :fullscreen="$vuetify.breakpoint.mdAndDown || dialogFullScreen"
                  :hide-overlay="$vuetify.breakpoint.mdAndDown" scrollable
                  no-click-animation>
            <v-card>
                <v-toolbar dark color="primary" :max-height="$vuetify.breakpoint.mdAndUp ? 64 : 56">
                    <v-btn icon dark @click="closeDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Maintenance</v-toolbar-title>
                    <v-progress-linear
                            :active="saveLoading"
                            :indeterminate="saveLoading"
                            absolute
                            bottom
                            color="lime"
                    ></v-progress-linear>
                    <div class="flex-grow-1"></div>
                    <v-toolbar-items>
                        <v-btn v-if="$vuetify.breakpoint.mdAndUp" icon @click="dialogFullScreen = !dialogFullScreen">
                            <v-icon v-if="dialogFullScreen === true">mdi-fullscreen-exit</v-icon>
                            <v-icon v-if="dialogFullScreen === false">mdi-fullscreen</v-icon>
                        </v-btn>
<!--                        <v-btn dark text @click="passes(submit)">-->
<!--                            <v-icon>mdi-content-save-outline</v-icon>-->
<!--                        </v-btn>-->
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text class="pa-5">
                    <v-btn @click="reloadApp()">Reload app</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </ValidationObserver>

</template>

<script>
    import {ValidationObserver} from 'vee-validate';

    export default {
        name: 'maintenance-dialog',

        props: {},

        components: {
            ValidationObserver,
        },

        data: () => ({
            dialog: false,
            dialogFullScreen: false,
            saveLoading: false,
        }),

        created() {
        },

        mounted() {

        },

        computed: {

        },

        watch: {},

        methods: {
            openDialog() {
                return new Promise(resolve => {
                    // this.loading = true;

                    this.dialog = true;
                    resolve()
                })
            },

            closeDialog() {
                return new Promise(resolve => {
                    this.dialog = false;
                    resolve()
                })
            },

            submit() {
                this.saveLoading = true;

                // ModelLogic
                //     .attach('units-groups', UnitGroupStore.unitGroup.id, 'units', this.selected)
                //     .then((model) => {
                //         this.$emit('update-unit', model)
                //         this.saveLoading = false;
                //         this.selected = [];
                //         this.closeDialog()
                //     })
                //     .catch((err) => {
                //         this.saveLoading = false;
                //         console.error(err)
                //     })
            },

            reloadApp(){
                window.location.reload()
            },
        },
    };
</script>
