<template>
	<v-app>
		<app-bar v-if="AppStore.started_app && AppStore.data.configuration.display_header_title_1"></app-bar>
		<v-main>
			<v-slide-x-transition mode="out-in">
				<router-view></router-view>
			</v-slide-x-transition>
			<v-snackbar
				v-model="snackbar"
				:timeout="timeout"
				:color="color"
			>
				<div v-html="text"></div>

				<template v-slot:action="{ attrs }">
					<v-btn
						color="blue"
						icon
						v-bind="attrs"
						@click="snackbar = false"
					>
						X
					</v-btn>
				</template>
			</v-snackbar>
		</v-main>
		<v-footer app v-if="AppStore.started_app && $route.name === 'tb-3-home'" padless
		          :color="!AppStore.data.general.dark_mode ? 'white' : ''">
			<v-container fluid>
				<v-row id="carrousel-row">
					<v-col v-if="AppStore.data.configuration.display_timeline_slider" class="mt-auto pa-0 d-flex"
					       id="carrousel">
						<ratings-carousel></ratings-carousel>
						<v-spacer v-if="AppStore.rating.rate === 0"></v-spacer>
						<router-link :to="{ name: 'tb-3-timeline'}" custom v-slot="{ navigate }"
						             v-if="$vuetify.breakpoint.smAndUp && AppStore.rating.rate === 0 && $route.name === 'tb-3-home' && AppStore.data.configuration.display_timeline_btn">
							<v-btn @click="navigate" @keypress.enter="navigate" role="link" rounded elevation="0" dark
							       :large="AppStore.data.general.button_size === 'large'"
							       :x-large="AppStore.data.general.button_size === 'x-large'"
							       :x-small="AppStore.data.general.button_size === 'x-small'"
							       :small="AppStore.data.general.button_size === 'small'"
							       :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
							       :color="AppStore.data.general.primary_color.hexa"
							       class="text-button mr-11 ml-1 mt-6">
								{{ AppStore.data.configuration[AppStore.locale].timeline_btn_text }}
							</v-btn>
						</router-link>
					</v-col>
				</v-row>
			</v-container>
		</v-footer>

		<v-footer app v-if="AppStore.started_app && $route.name === 'tb-3-questions'" padless id="footer"
		          :color="!AppStore.data.general.dark_mode ? 'white' : ''">
			<v-container fluid>
				<v-row id="cgu-row">
					<v-col class="text-center" id="cgu">
						<div v-html="AppStore.data.configuration[AppStore.locale].information_txt"></div>
						<span v-html="AppStore.data.configuration[AppStore.locale].cgu_txt"></span> {{ ' ' }}
						<span v-html="AppStore.data.configuration[AppStore.locale].cgu_btn"
						      @click="AppStore.toggleCGU = !AppStore.toggleCGU" class="font-weight-bold"></span>
					</v-col>
				</v-row>
			</v-container>
		</v-footer>
	</v-app>
</template>

<script>
import AppStore from "../../../stores/AppStore";
import AppBar from "../../../components/communs/AppBar.vue";
import RatingsCarousel from "../../../components/tb/1/RatingsCarousel.vue";
import _ from "lodash";
import {EventBus} from "../../../services/Request";

import ModelLogic from "../../../logics/ModelLogic";
import PouchDB from "pouchdb";
import moment from "moment";
import Helpers from "../../../services/Helpers";

export default {
	name: 'Layout',

	mounted() {
		if (!_.includes(['tb-3-home', 'tb-3-token'], this.$route.name) && !AppStore.started_app) {
			console.log('GO HOME FROM LAYOUT')
			this.$router.push({name: 'tb-3-home'})
		}

		EventBus.$on('show-snackbar', (data) => {
			this.snackbar = false; //reset todo need stacked snack
			this.text = data.text || 'No information';
			this.timeout = data.timeout || 2000;
			this.color = data.color || 'green';
			this.snackbar = true;
		});
	},

	created() {
		EventBus.$on('getApp', (data) => {
			this.getApp(data)
		});

		EventBus.$on('launchHomeWatcher', (data) => {
			this.activityWatcher('home')
		});

		EventBus.$on('storeRating', () => {
			this.storeRating('EventBus created', true)
		});

		EventBus.$on('setQuestionAnswer', (data) => {
			this.setQuestionAnswer(data)
		});
	},

	components: {
		AppBar,
		RatingsCarousel
	},

	data: () => ({
		AppStore,
		snackbar: false,
		text: '',
		timeout: 2000,
		color: 'primary',

		env: process.env.VUE_APP_ENV,
		appDomain: process.env.VUE_APP_APPS_HOST,
		checkingID: null,

		timeoutID: null,
		secondsSinceLastActivity: 0,
		maxInactivity: 0,
		activityEvents: [
			'mousedown', 'mousemove', 'keydown',
			'scroll', 'touchstart'
		],
	}),

	computed: {
		Helpers() {
			return Helpers
		}

	},

	watch: {
		$route(to, from) {
			const pathTo = to.name.split('-').slice(0, 3)
			if (!_.includes(['tb-3-home', 'tb-3-token'], this.$route.name)) {
				this.clearInterval()
				this.activityWatcher(pathTo[2]);
			}

			if (pathTo[2] === 'home') {
				this.clearInterval()
				this.resetRating('routechange')
				this.setQuestionAnswerFromUrlParams()
			}
		}
	},

	methods: {
		actualRouteTimer(path) {
			return parseInt(AppStore.data.general.pages_delay[path])
		},

		activityWatcher(path) {
			this.maxInactivity = this.actualRouteTimer(path);
			// console.log(this.maxInactivity)
			this.clearInterval()

			this.timeoutID = setInterval(() => {
				this.secondsSinceLastActivity++;
				// console.log(this.secondsSinceLastActivity + ' seconds since the user was last active');
				//if the user has been inactive or idle for longer
				//then the seconds specified in maxInactivity
				// console.log(this.secondsSinceLastActivity)
				// console.log(this.maxInactivity)
				if (this.secondsSinceLastActivity >= this.maxInactivity) {
					this.clearInterval()
					// console.log('User has been inactive for more than ' + this.maxInactivity + ' seconds');
					//Redirect them to your logout.php page.
					// if (this.env !== 'local') {
					this.goInactive(path);
					// }
				}
			}, 1000);

			// console.log(this.timeoutID)
			this.activityEvents.forEach((eventName) => {
				document.addEventListener(eventName, this.activity, true);
			});
		},

		clearInterval() {
			clearInterval(this.timeoutID);
		},

		goInactive(path) {
			if (path !== 'timeline' && AppStore.rating.rate > 0) {
				this.storeRating('goInactive')
			}

			if (!_.includes(['tb-3-home'], this.$route.name)) {
				this.$router.push({name: 'tb-3-home', query: AppStore.query_params})
			}
		},

		activity() {
			this.secondsSinceLastActivity = 0;
		},

		refreshApp() {
			if (AppStore.is_connected) {
				EventBus.$emit('getApp', {
					type: AppStore.data.type,
					model: AppStore.data.model,
					slug: AppStore.data.slug,
					noredirect: true
				})
			} else {
				console.log("Pas de connexion internet (refreshApp)")
			}
		},

		storeRating(from, noReset = false) {
			// console.log('storeRating' + from)
			AppStore.rating.posted_at = moment().utc().format("DD/MM/YYYY HH:mm:ss")
			AppStore.rating.device_id = AppStore.data.device ? AppStore.data.device.id : null
			AppStore.rating.source = AppStore.data.source
			AppStore.rating.app_id = AppStore.data.id
			AppStore.rating.locale = AppStore.locale
			AppStore.rating.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
			// console.log('call storeRating from '+ from + ' '  + AppStore.rating)
			AppStore.db.post({
				rating: AppStore.rating
			}).then((response) => {

				//TODO IF NOT OK SEND NOTIF
				// console.log(response.ok === true)

				this.sendRatings(from + ' storeRating')
				if (!noReset) {
					this.resetRating('storeRating')
				}
			}).catch(function (err) {
				console.log(err);
			});
		},

		resetRating() {
			AppStore.rating = _.cloneDeep(AppStore.defaultRating)
		},

		sendRatings(from) {
			//POUR chaque rating en bdd local, send rating to API
			if (AppStore.is_connected) {
				// console.log('call sendRatings from ' + from)
				AppStore.db.allDocs({
					include_docs: true,
					// attachments: true
				}).then(function (ratings) {
					_.forEach(ratings.rows, (rating, index) => {
						let ratingToSend = rating.doc.rating
						ratingToSend.uuid = rating.id

						ModelLogic
							.store('ratings/post/liz', ratingToSend)
							.then(() => {
								AppStore.db.remove(rating.id, rating.value.rev)
							})
							.catch((error) => {
								// if (error.response.data.errors) {
								//     this.$refs.observer.setErrors(error.response.data.errors);
								// }
								console.error(error)
							})
					});
				}).catch(function (err) {
					console.log(err);
					//TODO SEND ERROR MESSAGE
				});
			}
		},

		async getApp(data) {
			// console.log("IN getApp tb1")
			if (AppStore.is_connected) {
				// console.log(data)
				await ModelLogic
					.getOne('apps', data.type + '/' + data.model + '/' + data.slug, {
						with: ['unit.ratings.data', 'unit.ratings.consumer', 'unit.ratings.contents', 'unit.ratings.answers', 'unit.statistics']
					})
					.then((apps) => {
						if (!AppStore.started_app) {
							AppStore.db = new PouchDB('liz_' + apps.id, {auto_compaction: true})

							AppStore.db.info().then(function (info) {
								// console.log(info);
							})
						} else {
							this.sendRatings(' getApp')
						}
						// console.log("version")
						if (AppStore.data.general && apps.general.version === AppStore.data.general.version) {
							// console.log(AppStore.data.general.version)
							// console.log("MAJ ONLY UNIT RATINGS")
							AppStore.data.unit = apps.unit
						} else {
							// console.log("MAJ ALL APP")
							AppStore.data = apps
							// console.log(AppStore.data.general.version)
							// AppStore.app_version = AppStore.data.general.version
							this.$vuetify.theme.dark = AppStore.data.general.dark_mode
							if (AppStore.data.general.font_override) {
								document.head.insertAdjacentHTML("beforeend", '<link href="' + AppStore.data.general.font_override + '" rel="stylesheet"></link>')
							}
							document.head.insertAdjacentHTML("beforeend", '<style>' + AppStore.data.general.css_override + '</style>')
							// console.log(AppStore.is_first_launch)
							ModelLogic
								.store('apps/' + data.type + '/' + data.model + '/' + data.slug + '/updated', {'app_updated_at': moment.now()})
								.then(() => {
								})
								.catch((error) => {
									console.error(error)
								})
							if (!AppStore.is_first_launch) {
								window.location.reload()
							}
						}

						AppStore.started_app = true

						if (!data.noredirect && this.$route.name !== 'tb-3-home') {
							// console.log("GET APP PUSH HOME ROUTE")
							this.$router.push({name: 'tb-3-home', params: {slug: data.slug}, query: data.params})
						}
					})
					.catch((err) => {
						console.error(err)
						AppStore.app_is_loading = false;
						// AppStore.started_app = false
						EventBus.$emit('show-snackbar', {
							text: 'Application non trouvée',
							timeout: 10000,
							color: 'error'
						})
					}).finally(() => {
						if (AppStore.started_app) {
							this.setQuestions()
							// this.setHiddenQuestions();
							this.startChecking()
							// if (!_.includes(['tb-3-home', 'tb-3-token'], this.$route.name)) {
							//     this.startTimer(this.actualRouteTimer);
							// }
						}
						AppStore.app_is_loading = false;
						AppStore.is_first_launch = false;
						// console.log('apps')
						// console.log(AppStore.data)
					})
			} else {
				console.log("Pas de connexion internet (getApp)")
			}
		},

		startChecking(timeout) {
			// console.log('IN startChecking')
			this.checkingID = setTimeout((slug) => {
				//TEST CONNEXION
				try {
					fetch(this.appDomain + 'assets/test_connection.html').then((response) => {
						// console.log(response)
						AppStore.is_connected = response.status === 200 || response.status === 304;
						this.refreshApp()

						// console.log(response.status); // returns 200
						// console.log(response); // returns 200
					}).catch((error) => {
						console.log(error)
						this.startChecking()
						AppStore.is_connected = false
					})
					// console.log('response.status: ', response.status);
					// console.log(response);
				} catch (err) {
					AppStore.is_connected = false
					this.startChecking()
					console.log(err);
				}


				// }, AppStore.data.general.checking_delay * 6000);
			}, AppStore.data.general.checking_delay * 60000);
		},

		async setQuestions() {
			AppStore.bookmarkedQuestions = await this.filterBookmarkedQuestions()
			AppStore.conditionedQuestion = await this.filterConditionedQuestion()
			AppStore.conditionalQuestions = await this.filterConditionalQuestions()
			AppStore.data.questions = await this.filterQuestions();
			AppStore.questions_by_page = _.groupBy(_.orderBy(AppStore.data.questions, ['is_bookmarked'], ['desc']), 'page');
			await this.setQuestionAnswerFromUrlParams()
			EventBus.$emit('started-app')
		},

		async filterQuestions() {
			return _.filter(AppStore.data.questions, function (q) {
				// if (!q.is_bookmarked) {
				q.must_be_hide = q.is_hidden || q.conditional_question_id;
				return q
				// }
			});
		},

		async filterConditionedQuestion() {
			return _.filter(AppStore.data.questions, (q) => {
				if (!_.isEmpty(q.conditional_question_id)) {
					return q
				}
			});
		},

		async filterConditionalQuestions() {
			return _.map(_.filter(AppStore.data.questions, (q) => {
				if (!_.isEmpty(q.conditional_question_id)) {
					return q.conditional_question_id
				}
			}), 'conditional_question_id.id');
		},

		async filterBookmarkedQuestions() {
			return _.groupBy(_.filter(AppStore.data.questions, (q) => {
				return q.is_bookmarked
			}), 'page')
		},

		async setQuestionAnswerFromUrlParams() {
			if (!_.isEmpty(this.$route.query)) {
				AppStore.query_params = this.$route.query;
				_.forEach(Object.entries(AppStore.query_params), (param, index) => {
					_.forEach(AppStore.data.questions, (questions, index) => {
						if (questions.short_name === param[0]) {
							this.$set(AppStore.rating.questions_answers, questions.id, {
								question_id: questions.id,
								content: param[1]
							})
						}
					});
				});
			}
		},

		checkIfConditonal (data) {
			// console.log("checkifConditonal")
			// console.log(AppStore.conditionalQuestions)//only ids
			if (AppStore.conditionalQuestions.length && _.includes(AppStore.conditionalQuestions, data.question.id)) {
				// console.log("question id")
				// console.log(data.question)
				// console.log("filtered questions conditional")
				// console.log(_.filter(
				// 	AppStore.conditionedQuestion, function (q) {
				// 		return q.conditional_question_id.id === data.question.id;
				// 	}
				// ))//QUESTIONS OBJECT
				// console.log("all condition questions")
				// console.log(AppStore.conditionedQuestion)//QUESTIONS OBJECT
				_.each(_.filter(
					AppStore.conditionedQuestion, function (q) {
						return q.conditional_question_id.id === data.question.id;
					}), (conditionedQuestion, i) => {


					const index = _.findIndex(AppStore.questions_by_page[conditionedQuestion.page], (q) => {
						return q.id === conditionedQuestion.id;
					}, 0)
					if (index !== -1) {
						if (data.question.format === 'select' && Array.isArray(data.inputValue)) {
							// console.log(conditionedQuestion.conditional_answer.split('~'))
							// console.log(data.inputValue)
							// console.log(data.inputValue.some(r=> conditionedQuestion.conditional_answer.split('~').includes(r)))
							AppStore.questions_by_page[conditionedQuestion.page][index].must_be_hide = !data.inputValue.some(r=> conditionedQuestion.conditional_answer.split('~').includes(r))
							// console.log('if multiple answer')
							// EventBus.$emit('refreshQuestions')

						} else if (conditionedQuestion.is_conditional_answer_regex) {
							const regex = new RegExp(conditionedQuestion.conditional_answer);
							// if(AppStore.questions_by_page[conditionedQuestion.page][index].must_be_hide != regex.test(data.inputValue) ? false : true) {
							// 	EventBus.$emit('refreshQuestions')
							//
							// }
							AppStore.questions_by_page[conditionedQuestion.page][index].must_be_hide = regex.test(data.inputValue) ? false : true
							// console.log(conditionedQuestion.page + ' ')
							// console.log(conditionedQuestion.name + ' ')
							// console.log(regex.test(data.inputValue) ? false : true)
							// console.log('regex')

						} else {
							if(data.question.format === 'slider') {
								// console.log('else slider')
								AppStore.questions_by_page[conditionedQuestion.page][index].must_be_hide = conditionedQuestion.conditional_answer == conditionedQuestion.conditional_question_id.options.hint['fr'][data.inputValue].label ? false : true
							} else {
								// console.log('else else')

								AppStore.questions_by_page[conditionedQuestion.page][index].must_be_hide = conditionedQuestion.conditional_answer == data.inputValue ? false : true
							}

							// console.log(conditionedQuestion)
							// console.log(conditionedQuestion.name + ' ')
							// console.log(conditionedQuestion.conditional_answer) //Moyennes
							// console.log(data.inputValue) // 3
							//
							// console.log(conditionedQuestion.conditional_answer == data.inputValue ? false : true)

						}
						// console.log(conditionedQuestion.conditional_answer)
						// console.log(data)
					}
					EventBus.$emit('refreshQuestions')

					if (!data.show) {
						delete AppStore.rating.questions_answers[data.id]
						//TODO this.$refs['qi' + index][0].sliderValue = 0
					}
				})
			}
			// console.log('AppStore.rating')
			// console.log(AppStore.rating)
		},

		async setQuestionAnswer(data) {
			// console.log("setQuestionAnswer")
			await this.setAnswer(data)
			this.checkIfConditonal(data)
		},

		setAnswer(data) {
			if (_.includes(data.question.short_name.toLowerCase(), 'nps') && (data.inputValue === undefined || data.inputValue === null)) {
				delete AppStore.rating.questions_answers[data.question.id]
			} else {
				// console.log(data.inputValue)
				this.$set(AppStore.rating.questions_answers, data.question.id, {
					question_id: data.question.id,
					content: data.inputValue
				})
			}
		}
	}
};
</script>
<style>
.pointer {
	cursor: pointer;
}

.revert-btn {
	background-color: white;
}

#comment-help:before {
	opacity: 0.1
}

.timeline-row {
	background-color: white;
	border-radius: 8px;
}

#home-title {
	text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.carrousel-stars button, .timeline-row button {
	padding: 0px !important;
}

#offline-icon {
	color: red;
}

#questions-page .v-input input {
	max-height: 56px;
	height: 56px;
	font-size: 1.2rem;
}

#questions-page .v-messages__message {
	font-size: 0.9rem;
}

#close-cgu::before {
	font-weight: bold;
}

#appreciation-page .v-chip:hover::before {
	opacity: 0 !important;
}

#app-bar-logo .v-image__image--cover {
	background-size: contain !important;
}

.v-application .primary--text {
	color: inherit !important;
	caret-color: inherit !important;
}
</style>