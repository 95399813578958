<template>
	<v-app-bar :elevation="AppStore.data.configuration[AppStore.locale].header_title_1 ? '' : 0" :color="AppStore.data.configuration[AppStore.locale].header_title_1 ? '' : 'transparent'" app dense class="px-8" :height="AppStore.data.general.app_bar_size" id="header" @touchstart="activeHover" @touchend="resetHover">
            <v-spacer v-if="$vuetify.breakpoint.xsOnly"></v-spacer>
			<v-toolbar-title>
				<v-img
				max-height="150"
				max-width="170"
				id="app-bar-logo"
				:src="AppStore.data.general.client_logo"
			></v-img>
			</v-toolbar-title>
        <v-spacer v-if="$vuetify.breakpoint.xsOnly"></v-spacer>

        <!--			<router-link v-if="$route.name === 'rate' || $route.name === 'timeline'" :to="{ name: 'home'}"-->
<!--			             custom-->
<!--			             v-slot="{ navigate }">-->
<!--				<v-btn @click="navigate" @keypress.enter="navigate" role="link" icon>-->
<!--					<v-icon medium>{{ $route.name === 'rate' ? 'mdi-arrow-left' : 'mdi-home-outline' }}</v-icon>-->
<!--				</v-btn>-->
<!--			</router-link>-->

<!--			<router-link v-if="$route.name === 'questions'" :to="{ name: 'rate'}"-->
<!--			             custom-->
<!--			             v-slot="{ navigate }">-->
<!--				<v-btn @click="navigate" @keypress.enter="navigate" role="link" icon>-->
<!--					<v-icon medium>{{ 'mdi-arrow-left' }}</v-icon>-->
<!--				</v-btn>-->
<!--			</router-link>-->
			<v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
		<v-toolbar-title v-if="$vuetify.breakpoint.smAndUp && AppStore.data.configuration[AppStore.locale].header_title_1">
			<span id="header-unit-name" v-html="AppStore.data.configuration[AppStore.locale].header_title_1"></span>
		</v-toolbar-title>
		<v-icon class="ml-5" id="offline-icon" v-if="!AppStore.is_connected">mdi-wifi-off</v-icon>
		<LocaleChanger class="ml-5" v-if="$route.name.includes('home')"/>
        <maintenance-dialog ref="maintenanceDialog"></maintenance-dialog>
    </v-app-bar>
</template>

<script>
import LocaleChanger from "../LocaleChanger.vue";
import AppStore from "../../stores/AppStore";
import MaintenanceDialog from "../../views/tb/1/MaintenanceDialog.vue";
import _ from "lodash";
export default {
	name: 'app-bar',

	components: {
		LocaleChanger,
        MaintenanceDialog
	},

	props: {},

	data: () => ({
		AppStore,
        countMouseOver: 0,
        interval: null,
    }),

	created() {

	},

	mounted() {

	},

	computed: {
        _() {
            return _;
        },
	},

	watch: {},

	methods: {
        activeHover () {
            this.interval = setInterval(() => {
                this.countMouseOver++;
                // console.log(this.countMouseOver)
                if (this.countMouseOver === 6) {
                    this.$refs.maintenanceDialog.openDialog()
                }
            }, 1000)
        },

        resetHover () {
            clearInterval(this.interval)
            this.countMouseOver = 0
            // console.log('reset')
        },
	}
};
</script>
