import Request from '../services/Request'

export default class GlobalRepository {

    /**
     *
     * @param model
     * @param params
     * @param method
     * @param cancelToken
     * @returns {Promise<unknown>}
     */
    static getAll(model, params, method = 'GET', cancelToken) {
        return new Promise((resolve, reject) => {
            Request
                .make(method, `/${model}`, {params}, cancelToken)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error)
                })

        });
    }

    /**
     *
     * @param model
     * @param uuid
     * @param params
     * @returns {Promise}
     */
    static getOne(model, uuid, params = {}) {
        return new Promise((resolve, reject) => {
            Request
                .make('GET', `/${model}/${uuid}`, {params})
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error)
                })
        });
    }

    /**
     *
     * @param model
     * @param uuid
     * @param body
     * @returns {Promise}
     */
    static updateOne(model, uuid, body = {}) {
        return new Promise((resolve, reject) => {
            Request
                .make('PUT', `/${model}/${uuid}`, body)
                .then((result) => resolve(result))
                .catch((error) => reject(error))
        });
    }

    /**
     *
     * @param model
     * @param body
     * @returns {Promise}
     */
    static update(model, body = {}) {
        return new Promise((resolve, reject) => {
            Request
                .make('PUT', `/${model}`, body)
                .then((result) => resolve(result))
                .catch((error) => reject(error))
        });
    }

    /**
     *
     * @param model
     * @param uuid
     * @param attachedModel
     * @param body
     * @returns {Promise<any>}
     */
    static attach(model, uuid, attachedModel, body = {}) {
        return new Promise((resolve, reject) => {
            Request
                .make('PUT', `/${model}/${uuid}/${attachedModel}`, body)
                .then((result) => resolve(result))
                .catch((error) => reject(error))
        });
    }

    /**
     *
     * @param model
     * @param uuid
     * @param detachedModel
     * @param body
     * @returns {Promise<any>}
     */
    static detach(model, uuid, detachedModel, body = {}) {
        return new Promise((resolve, reject) => {
            Request
                .make('PATCH', `/${model}/${uuid}/${detachedModel}`, body)
                .then((result) => resolve(result))
                .catch((error) => reject(error))
        });
    }

    /**
     *
     * @param model
     * @param body
     * @returns {Promise<unknown>}
     */
    static store(model, body) {
        return new Promise((resolve, reject) => {
            Request
                .make('POST', `/${model}`, body)
                .then((result) => resolve(result))
                .catch((error) => reject(error))
        });
    }

    /* By UnitGroup */

    /**
     * @returns {Promise}
     */
    static getAllByParent(parentModel, model, parentUuid, params) {
        return new Promise((resolve, reject) => {
            Request
                .make('GET', `/${parentModel}/${parentUuid}/${model}`, {params})
                .then((result) => resolve(result))
                .catch((error) => reject(error))
        });
    }
}

